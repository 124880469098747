import React from 'react'

const Loader = () => {
  return (
    <div className = "loader">
       
    </div>
  )
}

export default Loader
